import $ from 'jquery';
import ko from 'knockout';
import Handlebars from "handlebars";
import {snakeToCamel} from '@utils';
import {chosen} from "@plugins";
import startCase from 'lodash/startCase';

const DUAL_GRADE_EXCEPTION = 'Dual Verification (M320)';
const CLASSIFICATION_EXCEPTION = /Classification.+\w+/;
const NON_PERFORMANCE_EXCEPTION = 'Non Performance';
const DESIGNATION_REGEX = /[M3]+[23]+\d/g;

let elements = {};
let tests;

function testsFromIds(testIds) {
  return tests.get(
    (testIds || '').split(',').map(function (id) {
      return parseInt(id, 10);
    })
  );
}

function SamplesViewModel() {
  const vm = this;

  this.selectedAnalysisType = ko.observable(null);
  this.testSetOptions = ko.observableArray();
  this.currentTestSet = ko.observableArray([]);
  this.currentTestSetId = ko.observable();
  this.selectedTests = ko.observableArray([]);
  this.activeSelection = ko.observable(null);
  this.activeCategory = ko.observable(null);
  this.hasCustomTestSet = ko.observable(false);
  this.currentTestSetSelection = undefined;

  /* Event Handlers */

  this.handleChangeAnalysisType = function (data, event) {
    const $select = $(event.target);
    const value = parseInt($select.val(), 10);
    this.setAnalyisType(value);
  };

  this.handleSelectTestSet = function (data, event) {
    if (!vm.currentTestSetSelection) return;

    const testIds = JSON.parse(JSON.stringify(vm.currentTestSetSelection.testIds));

    vm.currentTestSetId(vm.currentTestSetSelection.value);
    vm.currentTestSet(testIds);
    vm.selectedTests(testIds);
  };

  this.handleAddTest = function (test) {
    this.selectedTests.push(test);

    vm.activeCategory(
      vm.activeTestCategories().filter(function (category) {
        return category.label === test.testCategory.name;
      })[0]
    );
  };

  this.handleRemoveTest = function (test) {
    vm.selectedTests.remove(test);
  };

  this.handleSaveCustomTestSet = function () {
    vm.currentTestSet(vm.selectedTests());
    vm.currentTestSetId(undefined);
    vm.hasCustomTestSet(true);
    $.modal.close();
  };

  this.handleClearCustomTestSet = function () {
    const testIds = vm.currentTestSetSelection ? vm.currentTestSetSelection.testIds : [];

    vm.selectedTests(testIds);
    vm.currentTestSet(testIds);
    vm.hasCustomTestSet(false);
    $.modal.close();
  };

  this.handleSetActiveCategory = function (category) {
    vm.activeCategory(category);
  };

  /* Data Methods */

  this.setAnalyisType = function (id) {
    let options, option;

    if (elements.sampleAnalysisTypeOptions.length) {
      options = JSON.parse(elements.sampleAnalysisTypeOptions.val());
      option = options.filter(function (o) {
        return o.id === id;
      })[0];
    }

    if (option) {
      this.selectedAnalysisType(option);
    }
  };

  /* View Helpers */

  this.testSetOptionValue = function (item) {
    if (!item) return null;

    return {
      value: item.value,
      label: item.label,
      testIds: testsFromIds(item.testIdsString)
    };
  };

  this.showCustomTestSetSummary = ko.pureComputed(function () {
    return vm.currentTestSet().length > 0 && !vm.currentTestSetSelection;
  });

  this.requestedGrade = ko.pureComputed(function () {
    const analysisType = vm.selectedAnalysisType();

    return [firstRequestedGrade(analysisType), secondRequestedGrade(analysisType)];
  });

  this.activeTestCategories = ko.pureComputed(function () {
    let selectedTests = sortTestsByCategories(vm.selectedTests);
    let categories = selectedTests
      .map(function (test) {
        return test.testCategory.name;
      })
      .filter(function (value, index, self) {
        return self.indexOf(value) === index;
      });

    return categories.map(function (category) {
      return {
        label: category,
        count: selectedTests.filter(function (test) {
          return isCategory(test, category);
        }).length
      };
    });
  });

  this.groupedSelectedTests = ko.pureComputed(function () {
    return vm.activeTestCategories().map(function (category) {
      return {
        label: category.label,
        count: category.count,
        items: vm.selectedTests().filter(function (test) {
          return isCategory(test, category.label);
        })
      };
    });
  });

  this.selectedTestIds = ko.pureComputed(function () {
    return vm
      .selectedTests()
      .map(function (test) {
        return test.id;
      })
      .join(',');
  });

  this.activeTestList = ko.computed(function () {
    if (!vm.activeCategory()) return [];

    const activeCategory = vm.activeCategory();

    return vm
      .selectedTests()
      .filter(function (test) {
        return isCategory(test, activeCategory.label);
      })
      .sort(function (left, right) {
        return left.id - right.id;
      });
  });

  /* non-view methods */

  function isCategory(test, category) {
    return test.testCategory.name === category;
  }

  function sortTestsByCategories(tests) {
    return tests.sort(function (left, right) {
      return (
        left.testCategory.superCategoryOrder - right.testCategory.superCategoryOrder ||
        left.testCategory.sortOrder - right.testCategory.sortOrder
      );
    })();
  }

  function firstRequestedGrade(analysisType) {
    let label = '';
    let isVisible = false;
    let matches;

    if (analysisType) {
      matches = analysisType.name.match(DESIGNATION_REGEX) || [];
      isVisible = !analysisType.name.match(CLASSIFICATION_EXCEPTION);

      if (analysisType === NON_PERFORMANCE_EXCEPTION) {
        label = 'Grade';
      } else {
        label = (matches.length ? matches[0] : '') + ' Grade';
      }
    }

    return {
      isVisible: isVisible,
      label: label
    };
  }

  function secondRequestedGrade(analysisType) {
    let label = '';
    let isVisible = false;
    let matches;

    if (analysisType) {
      matches = analysisType.name.match(DESIGNATION_REGEX) || [];
      isVisible =
        !analysisType.name.match(CLASSIFICATION_EXCEPTION) &&
        (analysisType.name === DUAL_GRADE_EXCEPTION || matches.length > 1);
      label = [
        matches.length ? matches[analysisType.name === DUAL_GRADE_EXCEPTION ? 0 : 1] : '',
        'Grade',
        analysisType.name === DUAL_GRADE_EXCEPTION ? '2' : ''
      ].join(' ');
    }

    return {
      isVisible: isVisible,
      label: label
    };
  }
}

function initTestDataEvents($container) {
  $container
    .on($.modal.BEFORE_OPEN, function (event, modal) {
      const modalId = modal.$elm.attr('id');

      if (modalId === 'Modal_ChangeTestProcedure' || modalId === 'Modal_ChangeTestCriteria') {
        const sampleTestId = modal.$anchor.data('sampleTestId');
        const testId = modal.$anchor.data('testId');
        const index = modal.$anchor.data('testIndex');
        const isHybrid = modal.$anchor.data('isHybrid');
        const modalTemplate = Handlebars.compile(modal.$elm.find('script[type="text/x-handlebars-template"]').html());

        $.get(`/tests/${testId}.json?sample_test_id=${sampleTestId}`, function (response) {
          let html = modalTemplate({
            ...response,
            isHybrid: isHybrid,
            index: index
          });

          html = html.replace(/:sample_test_id/g, sampleTestId);
          html = html.replace(/:test_id/g, testId);

          modal.$elm.find('.js-form').html(html);

          setTimeout(function () {
            modal.$elm.find('input[type="radio"][data-selected="true"]').prop('checked', true);
            modal.$elm.find('.js-form').removeClass('is-hidden');

            chosen.init(modal.$elm);
          });

          switch (modalId) {
            case 'Modal_ChangeTestProcedure':
              modal.$elm.find('input[type="radio"]').on('change', function () {
                const $this = $(this);

                modal.$elm.find('input[data-available-option]').prop('disabled', true);
                $this.siblings('input[data-available-option]').prop('disabled', false);
              });
              break;
            case 'Modal_ChangeTestCriteria':
              modal.$elm.find('input[type="radio"]').on('change', function () {
                const $this = $(this);

                $this.parents('ul').find('input[data-available-option]').prop('disabled', true);
                $this.siblings('input[data-available-option]').prop('disabled', false);
              });
              break;
          }
        });
      }
    })
    .on($.modal.AFTER_CLOSE, function (event, modal) {
      modal.$elm.find('.js-form').addClass('is-hidden');
    });
}

function initTestFormEvents($container, vm) {
  elements.testSearch.on('typeahead:select', function (event, value) {
    vm.handleAddTest(value);
    $(this).typeahead('val', null);
  });

  $('body')
    .on('modal:before-open', function (event, modal) {
      switch (modal.$elm.attr('id')) {
        case 'Modal_CreateCustomTestSet':
          elements.testSearch.typeahead(
            {
              minLength: 3
            },
            {
              name: 'tests',
              displayKey: 'name',
              limit: 100,
              source: function (query, syncResults) {
                tests.search(query, function (suggestions) {
                  var currentTestIds = vm.selectedTests().map(function (test) {
                    return test.id;
                  });

                  syncResults(
                    suggestions.filter(function (suggestion) {
                      return currentTestIds.indexOf(suggestion.id) === -1;
                    })
                  );
                });
              },
              templates: {
                suggestion: Handlebars.compile(
                  [
                    '<div class="two-tier two-tier-multiweight">',
                    '<span class="two-tier-large">{{name}}</span>',
                    '<span class="two-tier-small">{{testCategory.name}}</span>',
                    '</div>'
                  ].join('\n')
                )
              }
            }
          );
          break;
      }
    })
    .on('modal:after-close', function (_, modal) {
      switch (modal.$elm.attr('id')) {
        case 'Modal_CreateCustomTestSet':
          elements.testSearch.typeahead('destroy');
          break;
      }
    });

  // attaching this event to `window` so Rails UJS can
  // emit to it more easily
  $(window).on('samples:reset-test-set', function () {
    vm.selectedTests([]);
    vm.currentTestSet([]);
    vm.currentTestSetId();
    vm.hasCustomTestSet(false);
    vm.currentTestSetSelection = undefined;
    elements.testSetSelect.val('').trigger('chosen:updated');
  });
}

function initializeSamplesForm($container) {
  let testSetOptions = [];

  elements.testIds = $container.find('#Sample_TestIds');
  elements.testSearch = $container.find('#Sample_TestSearch');
  elements.testSetSelect = $container.find('#Sample_TestSetSelect');
  elements.testSetId = $container.find('#Sample_TestSetId');
  elements.testSetOptions = $container.find('#Sample_TestSetOptions');
  elements.testSetSelect = $container.find('#Sample_TestSetSelect');
  elements.sampleAnalysisTypeId = $container.find('#sample_analysis_type_id');
  elements.sampleAnalysisTypeOptions = $container.find('#sample_analysis_type_options');

  try {
    testSetOptions = JSON.parse(elements.testSetOptions.val()).map(function (obj) {
      return Object.keys(obj).reduce(function (finalObj, key) {
        finalObj[snakeToCamel(key)] = obj[key];
        return finalObj;
      }, {});
    });
  } catch (error) {
  }

  $.get('/tests.json', function (response) {
    const vm = new SamplesViewModel();
    let initialTests, initialTestSetId, initialTestSetSelection;

    tests = new Bloodhound({
      datumTokenizer: Bloodhound.tokenizers.obj.whitespace('name'),
      queryTokenizer: Bloodhound.tokenizers.whitespace,
      identify: function (obj) {
        return obj.id;
      },
      local: response
    });

    tests.initialize();
    initialTests = testsFromIds(elements.testIds.val());
    initialTestSetId = elements.testSetId.val();
    initialTestSetSelection = testSetOptions.filter(function (option) {
      return option.value === parseInt(initialTestSetId, 10);
    });
    elements.testSetOptions.remove();

    ko.applyBindings(vm, $container[0]);

    vm.testSetOptions(testSetOptions);
    vm.currentTestSet(initialTests);
    vm.currentTestSetId(initialTestSetId);
    vm.selectedTests(initialTests);
    vm.setAnalyisType(parseInt(elements.sampleAnalysisTypeId.val(), 10));
    vm.currentTestSetSelection = vm.testSetOptionValue(initialTestSetSelection[0]);
    vm.hasCustomTestSet(!vm.currentTestSetSelection && vm.currentTestSet().length > 0);

    if (vm.currentTestSetSelection) {
      elements.testSetSelect
        .find('option')
        .filter(function (_, option) {
          return option.innerText === vm.currentTestSetSelection.label;
        })
        .prop('selected', true);
      elements.testSetSelect.trigger('chosen:updated');
    }

    initTestFormEvents($container, vm);
  });
}

function init(container) {
  const $container = $(container || 'body');

  if ($container.find('#Sample_Data_Form').length > 0) {
    initTestDataEvents($container);
  }

  if ($container.find('#Sample_Form').length > 0) {
    initializeSamplesForm($container);
  }
}

export const samples = {init};
