import $ from 'jquery';
import UAParser from 'ua-parser-js';

/**
 * A module for identifying and locking the current page's scroll position
 * when an overlay is opened, then reverting it afterward.
 */
export const scrollLock = function scrollLock() {
  /**
   * The active scroll position.
   * @private
   */
  let scrollPosition;

  /**
   * A top margin value to apply when necessary.
   * @private
   */
  let topMargin = 0;
  topMargin = getTopMargin();

  /**
   * A bottom margin value to apply when necessary.
   * @private
   */
  let bottomMargin = 0;
  bottomMargin = getBottomMargin();

  return {
    getScrollPosition: getScrollPosition,
    setState: setState,
    cachePosition: cachePosition,
    getTopMargin: getTopMargin,
    getSafeOverlayPosition: getSafeOverlayPosition
  };

  /**
   * Get the current cached scroll position.
   *
   * @return {number}
   */
  function getScrollPosition() {
    return scrollPosition;
  }

  /**
   * Sets a locked or unlocked state.
   *
   * @param isLocked Is scrolling to be locked?
   */
  function setState(isLocked) {
    const containerWrapper = document.querySelector('.l-container');
    const headerWrapper = document.querySelector('.l-header');
    const footerWrapper = document.querySelector('.l-footer');
    const topPosition = scrollPosition - topMargin;
    const overlayPosition = getSafeOverlayPosition();
    let footerHeight;

    /*
      Toggle the `hasOverlay` state on the body to add or remove `position: fixed`
      and `overflow: hidden`. This prevents the background from scrolling, while
      allowing the overlay to scroll.
    */
    $('body').toggleClass('has-overlay', isLocked);

    /*
      If we try to set `scrollTop` on the document while the body has fixed position,
      it won't be retained. To simulate this, we apply a negative vertical transform to
      the body while the overlay is open, using the cached scroll position. If the
      overlay is closed, just remove this value.
    */
    document.body.style.transform = isLocked ? `translateY(-${topPosition}px)` : '';

    /*
      We do, however, want to set `scrollTop` anyway. If the overlay is shown, this will
      be ignored; if it is hidden, this will adjust the scroll position of the document
      the exact opposite amount of the negative transform we just removed. The end result
      is the page will appear to retain its original scroll position.
    */
    document.documentElement.scrollTop = topPosition;

    /*
      We also have to adjust the position of the fixed navigation at the top (if present)
      and the footer or else it will vanish while the overlay is open. :/ We also need to set the
      height of the container based on the computed top position.
     */
    if (containerWrapper) {
      containerWrapper.style.height = isLocked ? `calc(100vh + ${topPosition}px)` : '';
    }

    if (headerWrapper) {
      headerWrapper.style.top = isLocked ? overlayPosition.top : '';

      if (overlayPosition.marginTop) {
        headerWrapper.style.marginTop = isLocked ? overlayPosition.marginTop : '';
      }
    }

    if (footerWrapper) {
      footerHeight = parseInt(window.getComputedStyle(footerWrapper).height, 10);

      footerWrapper.style.top = isLocked
        ? 'calc(100vh + ' + (parseInt(overlayPosition.top, 10) - footerHeight) + 'px)'
        : '';
    }
  }

  /**
   * Store the current scroll position of the document.
   */
  function cachePosition() {
    scrollPosition = document.documentElement.scrollTop + topMargin;
  }

  /**
   * Check for a wrapper, then get the top paddding value if found.
   *
   * @return {number}
   */
  function getTopMargin() {
    if (topMargin) return topMargin;

    const wrapper = document.querySelector('.l-wrapper');

    return wrapper ? parseInt(window.getComputedStyle(wrapper).paddingTop, 10) : 0;
  }

  function getBottomMargin() {
    if (bottomMargin) return bottomMargin;

    return 0;
  }

  /**
   * Safely calculate a top position for an element to transform during
   * an overlay event.
   *
   * Internet Explorer doesn't appear to properly position the overlay and its
   * backdrop. If the page is scrolled at all, both receive `top` with the scroll
   * position, as expected. Unlike newer browsers, though, IE will render the overlay
   * as if its parent is the window, not the parent body; as a result, it appears shifted
   * downward in proportion to the current scroll distance. Applying an equally reverse
   * negative margin "fixes" this issue at the expense of breaking everyone else.
   *
   * While browser sniffing is generally unreliable, in this specific case it is necessary
   * to check for the presence of IE, and add the negative margin when required. Otherwise
   * we set this value to `undefined` and React will ignore it.
   *
   * @param {number} topPosition The top position to potentially adjust.
   *
   * @return {number}
   */
  function getSafeOverlayPosition() {
    const topPosition = getScrollPosition() - getTopMargin();
    const parser = new UAParser();
    const name = parser.getBrowser().name;

    const top = isNaN(topPosition) ? '0' : topPosition + 'px';
    const marginTop = name === 'IE' && !isNaN(topPosition) ? '-' + topPosition + 'px' : undefined;

    return {
      top: top,
      marginTop: marginTop
    };
  }
};
